// Cinnamon
$(function() {
  // Cinnamon Analysis
  $('#analysis-form').submit(function(e) {
    $(this).hide();
    $('#uploading-message').show();
  });

  // Navbar
  $('.navbar-burger').click(function() {
      $('.navbar-burger').toggleClass('is-active');
      $('.navbar-menu').toggleClass('is-active');
  });

  // モーダル
  $('[id=modal]').on('click', function(e){
    $(".modal" + $(this).data('target')).addClass('is-active')
  });

  // モーダルを閉じる
  $('[id=modal-close]').on('click', function(e){
    $($(this).parents('.modal')).removeClass('is-active');
  });

  // 難易度選択チェックボックス
  $('[id=table-selector]').change(function(e) {
    var target = $(`[id^='q_by_difficulties_${$(this).data('target')}']`);
    if ($(this).prop('checked')) {
      target.prop('checked', true);
    } else {
      target.prop('checked', false);
    }
  });

  // Ajaxボタン 送信
  $('.ajax-button').on('click', function(e) {
    $(this).addClass('is-loading');
  });

  // Ajaxボタン 完了
  $('.ajax-button').on('ajax:success', function(e) {
    $(this).removeClass('is-loading');
  });

  // URL共有
  $('#share').on('ajax:success', function(e) {
    $('#result-wrapper').show();
    $('#result').val(e.detail[0]['url']);
    $('#share_to_twitter').attr('href', e.detail[0]['twitter_url'])
  });

  // 譜面のユーザー投票
  $('[id$=vote-button]').on('ajax:success', function(e) {
    if (e.detail[0]['is_upvote']) {
      $('#upvote-button').attr('disabled', true);
      $('#downvote-button').attr('disabled', false);
      $('#upvote-button-text').text('良譜面！に投票しました');
      $('#downvote-button-text').text('うーん...');
    } else {
      $('#upvote-button').attr('disabled', false);
      $('#downvote-button').attr('disabled', true);
      $('#upvote-button-text').text('良譜面！');
      $('#downvote-button-text').text('うーん...に投票しました');
    }
  });

  // 譜面のユーザー投票
  $('[id=vote-tag-button]').on('ajax:success', function(e) {
    $(this).parent().children('#vote-tag-button').hide();
    $(this).parent().children('#unvote-tag-button').show();
    $(this).parent().find('#tag-vote-count').text(e.detail[0]['count']);
  });

  // 譜面のユーザー投票取り消し
  $('[id=unvote-tag-button]').on('ajax:success', function(e) {
    $(this).parent().children('#vote-tag-button').show();
    $(this).parent().children('#unvote-tag-button').hide();
    $(this).parent().find('#tag-vote-count').text(e.detail[0]['count']);
  });

  // 譜面のタグ追加
  $('[id=add-tag-button]').on('ajax:success', function(e) {
    $('#added-tag-message').show();
    $('#added-tag-message .message-body').text(e.detail[0]['message']);
  });

  // フォロー
  $('[id$=follow-button]').on('ajax:success', function(e) {
    $('#unfollow-button').show();
    $('#follow-button').hide();
  });

  // フォロー解除
  $('[id$=unfollow-button]').on('ajax:success', function(e) {
    $('#unfollow-button').hide();
    $('#follow-button').show();
  });

  // ライバル登録
  $('[id$=add-rival-button]').on('ajax:success', function(e) {
    $('#remove-rival-button').show();
    $('#add-rival-button').hide();
  });

  // ライバル解除
  $('[id$=remove-rival-button]').on('ajax:success', function(e) {
    $('#remove-rival-button').hide();
    $('#add-rival-button').show();
  });

  // もっと見るボタン
  $('#see-more').click(function() {
    $(this).siblings('#table-tags').show();
    $('#show-less').show();
    $(this).hide();
  });

  // もっと見るの閉じるボタン
  $('#show-less').click(function() {
    $(this).siblings('#table-tags').hide();
    $(this).hide();
    $('#see-more').show();
  });

  (function () {
    getAjaxRequest = function(url, success) {
      $.ajax({
        type: 'GET',
        url: url,
        beforeSend: function(xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        dataType: 'json',
        success: function(data) {
          success(data);
        }
      });
    };
  }());
});
