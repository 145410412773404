import consumer from './consumer'

consumer.subscriptions.create('IrScoreLogChannel', {
  connected() {
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    const stream = document.getElementById('ir-stream');
    stream.insertAdjacentHTML('afterbegin', data['partial']);
  }
});
